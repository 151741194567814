// you can import modules from the theme lib or even from
// NPM packages if they support it…

// Some convenient tools to get you started…
import ReplaceObfuscatedEmailAddresses from "./components/ReplaceObfuscatedEmailAddresses";
import SwiperSlider from "./components/SwiperSlider";

// Initialise our components on jQuery.ready…
jQuery(function ($) {
	ReplaceObfuscatedEmailAddresses.init();
	SwiperSlider.init();

	$('[data-toggle]').on('click', function(e) {
		e.preventDefault();
		let target = `[data-js="${$(this).data(`toggle`)}"]`;
		$(target).toggleClass('invisible');
		$(target).toggleClass('pointer-events-none');
		$(target).toggleClass('opacity-0');
		$(target).toggleClass('!max-h-0');
		if('main-nav'===$(this).data(`toggle`)){
			$('[data-js="header-wrapper"]').toggleClass('shadow-xl');
		}
	});

	$('[data-toggle-answer]').on('click', function(e) {
		e.preventDefault();

		let wrapper = $(this).closest('[itemProp="mainEntity"]');
		let arrow = wrapper.find('[data-js="arrow"]');
		let target = wrapper.find('[data-js="answer"]');

		arrow.toggleClass('rotate-90');

		target.toggleClass('invisible');
		target.toggleClass('pointer-events-none');
		target.toggleClass('opacity-0');
		target.toggleClass('!max-h-0');
	});

	$('[data-js="group-click"]').on('click', function() {
		let $targetLink = $(this).find('[data-js="group-link"]');

		if($targetLink.length){
			window.location.href = $targetLink.attr('href');
		}
	} );

	$('form.wpcf7-form').on('wpcf7mailsent', function(event) {
		// Prevent default form submission behavior
		event.preventDefault();

		// Wraper
		let $form = $(this);

		// Remove the Labels
		$form.find('label').remove();
	});
});
