const $ = window.jQuery;
const $window = window.$window || $(window);
import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';

/**
 * Inits the SwiperSlider component.
 */
const swiperSlider = {

	init() {
		new Swiper('.swiperSlider', {
			modules: [Pagination],
			autoplay: {
				delay: 5000,
			},
			slidesPerView: "auto",
			centeredSlides: true,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			loop: true,
		});
	}

}

export default swiperSlider;
